@import url(https://fonts.googleapis.com/css2?family=Great+Vibes&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
    text-align: center;
    font-family: 'PT Serif', serif;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    padding: 4%;
    color: #261010d0;
}

table td{
    color:#261010d0;
  }

.App-content {
    flex: 1 1;
    background-color: rgb(255, 255, 255);
    margin-bottom: 2%;
}

.App-left-align {
    text-align: left;
}

.App-right-align {
    text-align: right;
}

.noWarp-align-right {
    text-align: right;
    white-space: nowrap;
}

.App-logo {
    height: 30vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        margin: 2%;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

h1 {
    padding-top: 4%;
    padding-bottom: 1%;
    font-family: 'Great Vibes', cursive;
}

h2 {
    font-family: 'Great Vibes', cursive;
}

.navbar-color {
    background-color: #bc5656;
    margin-left: -1%;
    width:100%;
    min-height: 80px;
    font-family: 'Montserrat';
    font-weight: 450;
    text-transform: uppercase;
    padding-left: 1%;
    font-size: 91%;
}

.container {
    max-width: 1200px !important;
}

.priceTag {
    margin-right: 4%;
    text-align: right;
    font-weight: bold;
    font-size: 120%;
}


.accordion-button {
    font-family: 'PT Serif';
}

.accordion-button:not(.collapsed) {
    color: #FFF  !important;
    background-color: #b35a5a !important;
}

  .accordion-button:link, .accordion-button:visited, .accordion-button:hover, .accordion-button:active  {
    background-color: #b35a5a !important;
      color:#FFF !important;
      text-decoration: none !important;
       border: hidden !important;
         border-color: #FFF !important;
      box-shadow: 0px !important;
  }

  .accordion-button:focus {
    z-index: 3;
    border-color: #FFF !important;
    outline: 0;
    box-shadow: 0 0 0 .25rem #FFF !important;
  }

a {
    color:#261010d0!important;
}

p {
    padding-top: 2%;
    line-height: 150%;
}

h1 {
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-align: center;
}

h1:before, h1:after {
    position: absolute;
    top: calc(70% - 22px);
    width: 49%;
    height: 30px;
    content: '';
    background-image: url(/static/media/dotted.14ec070b.svg);
}

h1:before {
    margin-left: -50%;
    text-align: right;
}

h1:after {
    margin-left: 1%;
}

.ul-multicolumn {
    columns: 3;
    -webkit-columns: 3;
    -moz-columns: s;
  }

